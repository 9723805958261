import React, { useEffect, useState } from 'react';
import { PlusIcon, StarIcon } from '@heroicons/react/20/solid'
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';
import { ENV, URL_PATH } from '../../constants'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ModelDetailPage = ({
  onGetModelById,
  getSimilarModel,
  onFavoriteModel,
  onUnfavoriteModel,
  isAuthenticated,
  modelById = {},
  idModel,
  relatedModels,
  refreshCollection,
  inProgressFavorite,
  favoriteLimited,
  onAnnounce,
  onRedirectTo
}) => {
  const [tabs, setTabs] = useState(['Snapshot']);
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const [isOnFavorite, setIsOnFavorite] = useState(false);

  const getModelById = id => {
    onGetModelById(id, true);
    getSimilarModel(id);
  };

  useEffect(() => {
    getModelById(idModel);
    setCurrentTab(tabs[0]);
  }, [idModel]);

  useEffect(() => {
    if (modelById?.quotes?.length) {
      setTabs(['Snapshot', 'Quotes']);
    } else {
      setTabs(['Snapshot']);
    }
  }, [modelById]);

  useEffect(() => {
    if (favoriteLimited && isOnFavorite) {
      onAnnounce({
        message: "Free users can save up to 5 models.",
        label: "Upgrade",
        onOk: () => { setIsOnFavorite(false); onRedirectTo(URL_PATH.PAYMENT.PATH) },
      })
    }
  }, [favoriteLimited]);

  useEffect(() => {
    if (!inProgressFavorite) {
      refreshCollection()
    }
  }, [inProgressFavorite]);

  const onClickFavorite = () => {
    if (modelById.is_favorited) {
      onUnfavoriteModel(modelById.id);
    } else {
      setIsOnFavorite(true);
      onFavoriteModel(modelById.id);
    }
  };
  return (
    <article className="min-w-full">
      <Helmet>
        <meta property="og:image" content={`${modelById?.cover}`} />
        <meta property="og:image:width" content="1200" />
         <meta property="og:image:height" content="675" />
        <meta property="twitter:image" content={`${modelById?.cover}`} />
        <meta property="twitter:image:width" content="1200" />
         <meta property="twitter:image:height" content="675" />
      </Helmet>
      <div>
        <div>
          <img className="aspect-[716/409] w-full object-cover" src={modelById.cover} alt="" />
        </div>
        <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
          <div className="flex items-end space-x-5">
            <div className="sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
              <div className="mt-6 min-w-0 flex-1 sm:hidden 2xl:block" />
              <div className="justify-stretch mt-6 flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                {isAuthenticated && (
                  <button
                    type="button"
                    className="inline-flex items-center rounded-full px-3 py-0.5 mr-2 text-xs font-medium cursor-pointer bg-neutral-900 text-neutral-200"
                    onClick={onClickFavorite}
                  >
                    {modelById.is_favorited ? (
                      <>
                        <StarIcon className="-ml-1 mr-0.5 h-5 w-5 text-neutral-200" aria-hidden="true" />
                        Remove from Collection
                      </>
                    ) : (
                      <>
                        <PlusIcon className="-ml-1 mr-0.5 h-5 w-5 text-neutral-200" aria-hidden="true" />
                        Add to Collection
                      </>
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="min-w-0 flex-1">
            <p className="mb-1 font-light text-accent-default uppercase">{modelById?.category?.name}</p>
            <h1 className="text-3xl font-bold text-neutral-900">{modelById.name}</h1>
          </div>
        </div>
      </div>


      <div className="mt-6 sm:mt-2 2xl:mt-5">
        <div className="border-b border-neutral-200">
          <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <button
                  key={tab}
                  type="button"
                  className={classNames(
                    currentTab === tab
                      ? 'border-accent-default text-neutral-900'
                      : 'border-transparent text-neutral-500 hover:text-neutral-700 hover:border-neutral-300',
                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                  )}
                  onClick={() => setCurrentTab(tab)}
                >
                  {tab}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {currentTab === tabs[0] ? (
        <>
          <div className="mx-auto my-6 max-w-5xl px-4 sm:px-6 lg:px-8 prose prose-sm prose-indigo">
            {modelById.content && parse(modelById.content)}
          </div>
          {relatedModels && !!relatedModels.length && (
            <div className="mx-auto max-w-5xl px-4 pb-12 sm:px-6 lg:px-8">
              <h2 className="text-sm font-medium text-neutral-500">Related Models</h2>
              <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
                {relatedModels.map((relatedModel) => (
                  <div
                    key={relatedModel.id}
                    className="relative flex items-center space-x-3 rounded-xl border border-neutral-300 bg-white px-6 py-4 focus-within:ring-2 focus-within:ring-neutral-400 focus-within:ring-offset-2 hover:border-neutral-400"
                  >
                    <div className="min-w-0 flex-1">
                      <p className="text-sm font-medium text-neutral-900">{relatedModel.name}</p>
                      <p className="text-sm text-neutral-500 font-light">{relatedModel.summary}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="mx-auto my-6 max-w-5xl px-4 sm:px-6 lg:px-8">
          {modelById?.quotes?.map(quote => (
            <div
              key={quote.id}
              className="relative flex items-center space-x-3 rounded-xl border border-neutral-300 bg-white mb-3 px-6 py-4 focus-within:ring-2 focus-within:ring-neutral-400 focus-within:ring-offset-2 hover:border-neutral-400"
            >
              <div className="min-w-0 flex-1">
                <p className="text-sm font-medium text-neutral-900">{quote?.guest?.name || quote.name}</p>
                <p className="mt-2 prose prose-sm prose-indigo max-w-full">
                  {quote.content && parse(quote.content)}
                </p>
              </div>
            </div>
          ))}
          {!modelById?.quotes?.length && (<p className="text-sm font-medium text-center text-neutral-500">No items to display</p>)}
        </div>
      )}

    </article>
  );
}

export default ModelDetailPage;
