import { connect } from 'react-redux';
import { MentalModelsPage } from '../../pages/index';
import agent from '../../agent';
import { MENTAL_MODEL, COMMON } from '../../actions';

const mapDispatchToProps = dispatch => ({
    getModelCategories: () => dispatch({
        type: MENTAL_MODEL.GET_CATEGORIES,
        payload: agent.KeyStack.getModelCategories()
      }),
    getSubjectByCategoryId: (data) => dispatch({
        type: data && data.page > 1 ? MENTAL_MODEL.GET_MORE_SUBJECT_BY_CATEGORY_ID : MENTAL_MODEL.GET_SUBJECT_BY_CATEGORY_ID,
        payload: agent.Model.getSubjectByCategoryId(data),
      }),
    getSubjectTrending: ({ page, keyWord }) => dispatch({
        type: page > 1 > 1 ? MENTAL_MODEL.GET_MORE_SUBJECT_TRENDING : MENTAL_MODEL.GET_SUBJECT_TRENDING,
        payload: agent.Model.getSubjectTrending({ page, keyWord }),
      }),
    getSubjectFavorite: (data) => dispatch({
        type: data && data.page > 1 ? MENTAL_MODEL.GET_MORE_SUBJECT_FAVORITE : MENTAL_MODEL.GET_SUBJECT_FAVORITE,
        payload: agent.Model.getSubjectFavorite(data),
      }),
    getSubjectAZ: ({ page, keyWord }) => dispatch({
        type: page > 1 ? MENTAL_MODEL.GET_MORE_SUBJECT_AZ : MENTAL_MODEL.GET_SUBJECT_AZ,
        payload: agent.Model.getSubjectAZ({ page, keyWord })
      }),
    onRedirectTo: to => dispatch({
        type: COMMON.REDIRECT_TO,
        to
      }),
    onUnload: () => dispatch({
        type: MENTAL_MODEL.PAGE_UNLOAD
      })
  });

function mapStateToProps(state) {
  return {
    ...state.mentalModel,
    isAuthenticated: state.common && state.common.isAuthenticated,
    currentUser: state.common && state.common.currentUser,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MentalModelsPage);
