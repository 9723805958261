import React, { useEffect, useState, Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PencilSquareIcon, Bars3BottomRightIcon, BarsArrowUpIcon } from '@heroicons/react/20/solid';
import { Tooltip } from 'flowbite-react';
import { Link } from 'react-router-dom';
import { ANSWER } from '../../../actions';

import { DivLoadingComponent, LoadingComponent, KeystackCard } from '../../../components';

import { SWITCH_KEY_STACK, URL_PATH } from '../../../constants';
import { useDialog } from '../../../hook';
import { ModelDetailDialog } from '../../dialog';
import AddAnswer from './AddAnswer';
import Newletter from '../../layouts/Newletter';


function AnswerPage({
  success,
  resetPage,
  getQuestionById,
  computedMatch,
  getKeyStackQuestionById,
  currentUser,
  deleteKeyStackById,
  onRedirectTo,
  getMoreKeyStackQuestionById,
  question = {},
  keystacks,
  inProgressKeyStackById,
  more,
  onAnnounce,
  getRelatedQuestions,
  relatedQuestions,
  inProgressRelatedQuestions,
}) {
  const [switchKeyStack, setSwitchKeyStack] = useState(SWITCH_KEY_STACK.top);
  const [page, setPage] = useState(1);

  const [itemDelete, onCloseDelete] = useDialog();
  const [itemAdd, onCloseAdd, onOpenAdd] = useDialog();
  const [showModelFor, setShowModelFor] = useState(null);

  useEffect(() => {
    if (success && itemDelete) {
      resetPage();
      onCloseDelete();
    }
  }, [success]);

  const onCloseSuccessAddDialog = (isUpdated) => {
    onCloseAdd();
    setSwitchKeyStack(SWITCH_KEY_STACK.latest);
    let message = 'Your Answer has been submitted.'
    if (isUpdated) {
      message = 'Your Answer has been edited.';
    }
    onAnnounce({
      message: <p className="name">Congratulations.<br />{message}</p>,
      label: 'OK'
    });
  };

  const isBlocked = () => {
    if (currentUser && currentUser.blocked) {
      onAnnounce({
        message: <p className="name">Your account has been blocked.<br />Contact administrator for details.</p>,
        label: 'OK'
      });
      return true;
    }
    return false;
  }

  const onOpenAddDialog = (updateStack) => {
    if (isBlocked()) {
      return;
    }
    if (updateStack) {
      onOpenAdd(updateStack);
      return;
    }
    if (!currentUser || !currentUser.id) {
      onRedirectTo(URL_PATH.LOGIN.PATH);
      return;
    }
    if (!question.is_comment) {
      onOpenAdd(true);
      return;
    }
    onAnnounce({
      message: (
        <p className="name existing">
          You have an existing Answer answering this question. Please
          delete your existing Answer to add new.
        </p>
      ),
      label: 'Got it'
    });
  };

  const getSort = switchValue => {
    let sort = 'total_respects';
    if (switchValue === SWITCH_KEY_STACK.top) {
      sort = 'total_respects';
    } else if (switchValue === SWITCH_KEY_STACK.latest) {
      sort = 'created_at';
    }
    return sort;
  };

  useEffect(() => {
    const { slug } = computedMatch.params;
    getQuestionById(slug);
    getRelatedQuestions(slug);
  }, [])

  useEffect(() => {
    const { slug } = computedMatch.params;
    getKeyStackQuestionById({
      id: slug,
      page: 1,
      sort: getSort(switchKeyStack)
    });
  }, [switchKeyStack]);

  const onClickMore = () => {
    const nextPage = page + 1;
    getMoreKeyStackQuestionById({
      id: computedMatch.params.slug,
      page: nextPage,
      sort: getSort(switchKeyStack)
    });
    setPage(nextPage);
  };

  const onDeleteKeystack = (id) => {
    onAnnounce({
      message: (
        <p>Are you sure you want to delete this Answer?</p>
      ),
      label: `Delete`,
      onOk: () => deleteKeyStackById(id)
    });
  }

  const handleModelDialog = (modelId) => {
    setShowModelFor(modelId)
  }

  const renderKeystacks = () => {
    if (inProgressKeyStackById && page === 1) {
      return <DivLoadingComponent />;
    }
    if (Array.isArray(keystacks) && keystacks.length === 0) {
      return (
        <div className="mt-2 text-center">
          <h2 className="text-md text-neutral-700">No Answers available</h2>
          <p className="font-light text-sm text-neutral-500">There are currently no answers answering this question. Feel free to add one now.</p>
        </div>
      )
    }
    if (!Array.isArray(keystacks) || keystacks.length === 0) {
      return null;
    }
    return keystacks.map((item) => (
      <KeystackCard
        key={item.id}
        item={item}
        question={question}
        hasToolbar
        isAnswer
        toggleModelDialog={handleModelDialog}
        toggleEditDialog={onOpenAddDialog}
        onDelete={onDeleteKeystack}
        actionPrefix={ANSWER}
      />
    ))
  }
  
  const renderRelatedQuestions = (questions) => {
    if (!Array.isArray(questions)) {
      return null;
    }

    if (Array.isArray(questions) && !questions?.length) {
      return <p className="mt-2 text-center font-light text-sm text-neutral-500">No items to display</p>
    }

    return (
      <ul className="-my-5 divide-y divide-neutral-200">
        {questions.map((questionItem) => (
          <li key={questionItem.id} className="py-3">
            <div className="relative focus-within:ring-2 focus-within:ring-cyan-500">
              <h3 className="text-sm font-normal text-neutral-800">
                <Link to={`/answers/${questionItem.slug}`} className="hover:underline focus:outline-none">
                  <span className="absolute inset-0" aria-hidden="true" />
                  {questionItem.question}
                </Link>
              </h3>
            </div>
          </li>
        ))}
      </ul>
    )
  }

  return (
    <>
      <main className="lg:col-span-9 xl:col-span-6">
      <h1 className="text-xl font-medium text-neutral-900 hover:no-underline pb-3 px-2">{question.question}</h1>
      <div className="flex justify-center items-center pb-3 text-neutral-700">
      {question.question && (
        <>
          {switchKeyStack === SWITCH_KEY_STACK.top ? (
            
              <button type="button" onClick={() => setSwitchKeyStack(SWITCH_KEY_STACK.latest)}>
                <Tooltip content="Sort by date created">
                  <Bars3BottomRightIcon className="h-6 w-6" />
                </Tooltip>
              </button>
          ) : (
            <button type="button">
              <Tooltip content="Sort by top answer">
                <BarsArrowUpIcon className="h-6 w-6" onClick={() => setSwitchKeyStack(SWITCH_KEY_STACK.top)} />
              </Tooltip>
            </button>
          )}
          <button
            type="button"
            className="flex justify-center rounded-full border border-gray bg-white ml-4 py-2 px-4 lg:mb-0 text-sm font-medium hover:bg-slate-50 focus:outline-none inline-flex items-center"
            onClick={() => onOpenAddDialog()}
          >
            <PencilSquareIcon className="h-5 w-5 mr-1" aria-hidden="true" /> Answer
          </button>
        </>
      )}
      </div>
        <InfiniteScroll
          dataLength={keystacks ? keystacks.length : 0}
          next={onClickMore}
          hasMore={more}
          loader={page === 1 ? null : <DivLoadingComponent />}
        >
          <ul className="space-y-4 pb-4">
            {renderKeystacks()}
          </ul>
        </InfiniteScroll>
      </main>
      <aside className="hidden xl:col-span-4 xl:block">
        <div className="sticky top-4 space-y-4 xl:overflow-y-auto xl:h-[calc(100vh-32px)]">
          <section aria-labelledby="trending-heading" className="rounded-xl shadow-[0px_0px_15px_-2px_rgba(0,0,0,0.1)] m-4 mt-0">
            <div className="rounded-xl bg-white">
              <div className="p-6">
                <h2 id="trending-heading" className="text-base font-medium text-neutral-900">
                  Related Questions
                </h2>
                <div className="mt-6 flow-root">
                  { inProgressRelatedQuestions ? (
                    <LoadingComponent />
                  ) : (
                    renderRelatedQuestions(relatedQuestions)
                  )}
                </div>
              </div>
            </div>
          </section>
          <Newletter />
        </div>
      </aside>
      {itemAdd && (
        <AddAnswer
          item={itemAdd}
          question={question}
          onClose={onCloseAdd}
          onCloseSuccess={onCloseSuccessAddDialog}
          computedMatch={computedMatch}
        />
      )}
      <ModelDetailDialog
          open={!!showModelFor}
          onClose={() => handleModelDialog(null)}
          idModel={showModelFor}
        />
    </>
  );
}

export default AnswerPage;
