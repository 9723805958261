import { connect } from 'react-redux';
import { COMMON } from '../../actions/common';
import { SAMPLE } from '../../actions/sample';
import agent from '../../agent';
import ModelDetailPage from '../../pages/sites/ModelDetailPage';

const mapDispatchToProps = dispatch => ({
    onGetModelById: (id, name) => dispatch({
        type: SAMPLE.GET_MODEL_BY_ID,
        payload: agent.Model.getModelById(id, name)
      }),
    onRedirectTo: to => dispatch({
        type: COMMON.REDIRECT_TO,
        to
      }),
    onFavoriteModel: id => dispatch({
      type: SAMPLE.FAVORITE_MODEL,
      payload: agent.Model.favouriteModel(id)
    }),
    onUnfavoriteModel: id => dispatch({
      type: SAMPLE.FAVORITE_MODEL,
      payload: agent.Model.unfavoriteModel(id)
    }),
    getSimilarModel: id => dispatch({
      type: SAMPLE.GET_SIMILAR_MODEL,
      payload: agent.Model.getSimilarModel(id)
    }),
    onAnnounce: (data) => dispatch({ type: COMMON.ANNOUNCE, data }),
  });

function mapStateToProps(state) {
  return {
    ...state.sample,
    isPremium: state.common && state.common.isPremium,
    isAuthenticated: state.common && state.common.isAuthenticated,
    message: state.common && state.common.message
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelDetailPage);
